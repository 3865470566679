<template>
  <div class="mt-4  px-5">
    <h2>Text Editor</h2>
    <editor
        api-key="3yqivr09xo7i95krxcytn121zfdzpwvp5n1e1l7glpf1zs21"
        output-format="html"
        v-model="TextMail"
        :init="{
         height: 500,
         menubar: true,
         plugins: [
           'advlist autolink lists link image charmap print preview anchor',
          'searchreplace visualblocks code fullscreen',
          'insertdatetime media table paste code help wordcount'
         ],
         toolbar:
           'undo redo | formatselect | bold italic backcolor | \
           alignleft aligncenter alignright alignjustify | \
           bullist numlist outdent indent | removeformat | help'
       }"
    />

    <v-dialog v-model="dialogSend" max-width="500px">
      <v-card>
        <v-card-title class="text-h5">Willst du wirklich senden?</v-card-title>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="closeDialoge">Abbrechen</v-btn>
          <v-btn color="blue darken-1" text @click="sendMail">OK</v-btn>
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-btn
      class="mt-5"
      @click="sendMail_Confirm"
      color="primary"><i class="far fa-save"></i> senden</v-btn>
  </div>
</template>

<script>
import Editor from '@tinymce/tinymce-vue';
export default {
  name: 'MassMail',
  components: {
    'editor': Editor
  },
  data: () => ({
    dialogSend: false,
    TextMail: 'Hier kann der Mail-Text stehen ...',
  }),
  computed: {
  },
  methods: {
    sendMail_Confirm() {
      this.dialogSend = true
    },
    closeDialoge() {
      this.dialogSend = false
    },
    sendMail() {
      var Data;
      Data = {
        TextData: this.TextMail
      };
      this.dialogSend = false
      this.$store.dispatch('AdminApp/setSendMail', Data)
    },

  },
}
</script>

<style scoped>

</style>