<template>
  <div>
    <v-btn color="indigo" dark="" class="mr-5 mb-2" @click="open_dialog()">
      <v-icon class="mr-2"
              x-small>
        fas fa-file-signature
      </v-icon>
      Neue Disziplin
    </v-btn>

    <v-dialog
        v-model="dialog_open"
        transition="dialog-top-transition"
        width="500">
      <v-card>
        <v-card-title class="text-h5 grey lighten-2">
          Neue Disziplin anlegen
          <v-spacer></v-spacer>
          <v-btn icon @click="dialog_open = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text>
          <v-form ref="form" v-on:submit.prevent>
            <v-container>
              <v-row>
                <v-col cols="3">
                  <v-subheader class="mySubheader">Nummer</v-subheader>
                </v-col>
                <v-col cols="8">
                  <v-text-field
                      v-model="New_Disciplines.Nummer"
                      :rules="[rules.required]"
                      :error-messages="errorMessages"
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="3">
                  <v-subheader class="mySubheader">Bund</v-subheader>
                </v-col>
                <v-col cols="8">
                  <v-select
                      v-model="New_Disciplines.bund"
                      :items="comp_bunds"
                      :menu-props="{ maxHeight: '400' }"
                      label="Bünde"
                      item-text="Kurz"
                      item-value="id"
                      value="id"
                      key="id"
                      :rules="[rules.required]"
                      hint="Bund zur Disziplin zuweisen"
                      persistent-hint
                  ></v-select>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="3">
                  <v-subheader class="mySubheader">Kurz Bezeichnung</v-subheader>
                </v-col>
                <v-col cols="8">
                  <v-text-field
                      v-model="New_Disciplines.KurzBez"
                      :rules="[rules.required]"
                      :error-messages="errorMessages"
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="3">
                  <v-subheader class="mySubheader">Lang Bezeichnung</v-subheader>
                </v-col>
                <v-col cols="8">
                  <v-text-field
                      v-model="New_Disciplines.LangBez"
                      :rules="[rules.required]"
                      :error-messages="errorMessages"
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="3">
                  <v-subheader class="mySubheader">Beschreibung</v-subheader>
                </v-col>
                <v-col cols="8">
                  <v-text-field
                      v-model="New_Disciplines.Beschreibung"
                      :rules="[rules.counter]"
                      :error-messages="errorMessages"
                  ></v-text-field>
                </v-col>
              </v-row>
            </v-container>
          </v-form>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
              color="green darken-1"
              text
              @click="dialog_open = false"
          >
            Abbrechen
          </v-btn>
          <v-btn
              color="green darken-1"
              text
              @click="save_new_disciplines"
          >
            Speichern
          </v-btn>
        </v-card-actions>

      </v-card>
    </v-dialog>
  </div>
</template>
<script>
export default {
  name: "disciplinesNew",
  data: () => ({
    dialog_open: false,
    errorMessages: '',
    DefaultDisciplines: {
      bund: {},
      Nummer: '',
      KurzBez: '',
      LangBez: '',
      Beschreibung: ''
    },
    New_Disciplines: {},
    rules: {
      required: value => !!value || 'Pflichtfeld.',
      counter: value => value.length <= 200 || 'Max 200 Zeichen',
    },
  }),

  computed: {
    comp_bunds() {
      return this.$store.getters['AdminApp/bunds'];
    },
  },
  methods: {
    open_dialog(){
      this.New_Disciplines = Object.assign({}, this.DefaultDisciplines)
      this.dialog_open = true;
    },
    close_dialog(){
      this.dialog_open = false;
    },
    async save_new_disciplines(){

      if (this.$refs.form.validate()) {
        await this.$store.dispatch('AdminApp/setNewDiscipline', this.New_Disciplines)
            .then(
                this.dialog_open = false
            )
      }
    },
  },
}
</script>


<style scoped>

</style>