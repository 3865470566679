<template>
  <div>
    <h3>Disziplinen</h3>
    <disciplines-table></disciplines-table>
  </div>
</template>


<script>
import disciplinesTable from "@/views/component/disciplinesTable.vue";

export default {
  name: "Disciplines",
  components: {disciplinesTable},
  data: () => ({}),

}
</script>


<style scoped>

</style>