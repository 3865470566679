<template>
  <div>
    <v-data-table
        :headers="headers"
        :items="comp_disciplines"
        :search="search"
        sort-by="KurzT"
        class="elevation-1 mx-10"
    >
      <template v-slot:top>
        <v-toolbar flat>
          <v-text-field
              v-model="search"
              append-icon="mdi-magnify"
              label="Suchen"
              class="mx-4 mt-5"
          ></v-text-field>
          <v-spacer></v-spacer>

          <disciplines-new></disciplines-new>

          <v-btn icon
                 @click="get_Disciplines">
            <v-icon>mdi-reload</v-icon>
          </v-btn>
        </v-toolbar>
      </template>


      <template v-slot:item.actions="{ item }">
        <v-btn
            class="info my-1 mx-1"
            text
            @click="edit_disciplines(item)">
          <v-icon>
            mdi-pencil
          </v-icon>
        </v-btn>

        <v-btn
            class="error my-1"
            text
            @click="prepare_delete_disciplines(item)">
          <v-icon>
            mdi-delete
          </v-icon>
        </v-btn>
      </template>

    </v-data-table>

    <v-dialog v-model="dialogDelete" max-width="500px">
      <v-card>
        <v-card-title class="text-h5">Willst du diesen Eintrag wirklich löschen?</v-card-title>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="dialogDelete = false; prepare_delete_item = {}">Abbrechen</v-btn>
          <v-btn color="blue darken-1" text @click="delete_Discipline()">OK</v-btn>
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>


  </div>
</template>

<script>
import disciplinesNew from "@/views/component/disciplinesNew.vue";
export default {
  name: "disciplinesTable",
  components: {disciplinesNew},
  data: () => ({
    search: '',
    dialogDelete: false,
    prepare_delete_item:{},

    headers: [
      {
        align: 'KurzT',
        sortable: false,
      },
      {text: 'Bund', value: 'bunds.Kurz', width: 100},
      {text: 'Nummer', value: 'Nummer', width: 100},
      {text: 'Kurz Text', value: 'KurzBez', width: 200},
      {text: 'Lang Text', value: 'LangBez', width: 400},
      {text: 'Beschreibung', value: 'Beschreibung', width: 400},
      {text: 'Actions', value: 'actions', sortable: false, width: 200}
    ],


  }),

  computed: {
    comp_disciplines() {
      return this.$store.getters['AdminApp/disciplines'];
    },
  },

  mounted() {
    this.get_Disciplines();
  },

  methods: {
    get_Disciplines() {
      this.$store.dispatch('AdminApp/getDisziplinenData')
      this.$store.dispatch('AdminApp/getBundData')
    },
    edit_disciplines(item) {
      console.log(item);
    },
    prepare_delete_disciplines(item) {
      this.dialogDelete = true;
      this.prepare_delete_item = Object.assign({}, item)
    },

    async delete_Discipline() {
      const Data = {
        'discipline_id': this.prepare_delete_item.id
      }

      await this.$store.dispatch('AdminApp/delDiscipline', Data)
          .then(this.dialogDelete = false,
              this.prepare_delete_item = {}
          )
    },

  },
}
</script>

<style scoped>

</style>